import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Boxes from "../components/boxes"
import CtaContact from "../components/cta-contact"
import Seo from "../components/seo"

const IntegrationFramework = ({ data }) => {

  const hero = {
    'textintro': "Encapsulate your insights into API’s, and share it with your technology landscape in real-time. Embed security, monitor usage, and accelerate onboarding of new technologies.",
    'title': 'Integration Framework',
    'squares': '',
    'squaresmove': false,
  }

  const box1 = {
    'title': '',
    'content': [
      {
        'title': 'Focus on business effectiveness over efficiency',
        'content': 'Focus on business outcomes with a premium on effectiveness and then align re-engineering efforts to drive toward those outcomes.'
      },
      {
        'title': 'Understand your processes first, then re-engineer',
        'content': 'Don’t assume you know everything about how each daily function in your organization runs. The fact is that if you ask five people to describe the same process, you could get five different answers.',
      },
      {
        'title': 'Re-think your tools',
        'content': 'The tools that we’ve had to date are either too big and complex or too small and siloed. The speed of change is now the key driver to adopt and adapt fast.',
      }
    ],
  }

  return (
    <Layout
      linksnavcolor='black'
    >
      <Seo
        title={data.site.siteMetadata.title}
        description="Encapsulate your insights into API’s, and share it with your technology landscape in real-time. Embed security, monitor usage, and accelerate onboarding of new technologies"
      />
      <Hero
        bg='linear-gradient(180deg, #ffceba 96%, #000000)'
        color='black'
        textintro={hero.textintro}
        title={hero.title}
        squares={hero.squares}
        squaresmove={hero.squaresmove}
      />
      <Boxes
        colorsquares='secondary'
        bg='linear-gradient(180deg, #000000, #1e1e1e)'
        title={box1.title}
        columns={box1.content.length}
        content={box1.content}
      />
      <CtaContact />
    </Layout>
  )
}

export default IntegrationFramework

export const query = graphql`
  query IntegrationFramework {
    site {
      siteMetadata {
        title
      }
    }
  }
`
